module Main exposing (main)

import Browser
import Config exposing (Gruppe, Projekt)
import Html exposing (Html, a, details, div, h1, span, summary, table, td, text, th, tr)
import Html.Attributes exposing (class, href, style)


main : Program String Model ()
main =
    Browser.element
        { init = \f -> init f |> (\i -> ( i, Cmd.none ))
        , update = \_ m -> ( m, Cmd.none )
        , view = view
        , subscriptions = \_ -> Sub.none
        }


type alias Bewertung =
    { rolleBewerter : String
    , nameBewerter : String
    , rolleBewerteter : String
    , nameBewerteter : String
    , projekte : List String
    }


bewertungen : List Projekt -> AbteilungCheck -> Gruppe -> List Bewertung
bewertungen projekte abteilung gruppe =
    gruppe.mitglieder
        |> List.concatMap
            (\mitglied ->
                (projektBewertungen abteilung projekte
                    |> List.filter (\bew -> compareCi bew.nameBewerteter mitglied)
                )
                    ++ gruppenLeiterBewertung mitglied gruppe.gruppenLeiter
            )


projektBewertungen : AbteilungCheck -> List Projekt -> List Bewertung
projektBewertungen abteilung =
    List.concatMap
        (\projekt ->
            let
                f =
                    combine (\_ _ -> True) projekt.name

                a =
                    combine abteilung projekt.name
            in
            [ f "PL" projekt.projektLeiter "MA" projekt.mitarbeiter
            , f "MA" projekt.mitarbeiter "PL" projekt.projektLeiter
            , a "MA (TL)" projekt.technischeLeiter "MA" projekt.mitarbeiter
            , a "MA" projekt.mitarbeiter "MA (TL)" projekt.technischeLeiter
            , f "PL" projekt.projektLeiter "MA (TL)" projekt.technischeLeiter
            , f "MA (TL)" projekt.technischeLeiter "PL" projekt.projektLeiter
            ]
        )
        >> List.concat
        >> groupProjects


groupProjects : List Bewertung -> List Bewertung
groupProjects bs =
    bs
        |> List.sortBy (\x -> [ x.rolleBewerter, x.nameBewerter, x.rolleBewerteter, x.nameBewerteter ])
        |> List.foldl
            (\a b ->
                case b of
                    [] ->
                        [ a ]

                    h :: ts ->
                        if
                            compareCi h.rolleBewerter a.rolleBewerter
                                && compareCi h.nameBewerter a.nameBewerter
                                && compareCi h.rolleBewerteter a.rolleBewerteter
                                && compareCi h.nameBewerteter a.nameBewerteter
                        then
                            { h | projekte = h.projekte ++ a.projekte } :: ts

                        else
                            a :: b
            )
            []


gruppenLeiterBewertung : String -> String -> List Bewertung
gruppenLeiterBewertung mitglied gruppenLeiter =
    [ { rolleBewerter = "GL"
      , nameBewerter = gruppenLeiter
      , rolleBewerteter = "MA"
      , nameBewerteter = mitglied
      , projekte = []
      }
    , { rolleBewerter = "MA"
      , nameBewerter = mitglied
      , rolleBewerteter = "GL"
      , nameBewerteter = gruppenLeiter
      , projekte = []
      }
    ]


type alias AbteilungCheck =
    String -> String -> Bool


combine : AbteilungCheck -> String -> String -> List String -> String -> List String -> List Bewertung
combine abteilung projektName rolleBewerter bewerterList rolleBewerteter bewerteterList =
    bewerterList
        |> List.concatMap
            (\bewerter ->
                bewerteterList
                    |> List.filter (\bewerteter -> abteilung bewerter bewerteter)
                    |> List.map
                        (\bewerteter ->
                            { rolleBewerter = rolleBewerter
                            , nameBewerter = bewerter
                            , rolleBewerteter = rolleBewerteter
                            , nameBewerteter = bewerteter
                            , projekte = [ projektName ]
                            }
                        )
            )


type alias Model =
    { configEncoded : String
    , gruppen : List Gruppe
    , projekte : List Projekt
    }


init : String -> Model
init config =
    let
        cfg =
            Config.unpack config |> Maybe.withDefault "" |> Config.parse |> Result.withDefault { projekte = [], gruppen = [] }
    in
    { configEncoded = config
    , projekte = cfg.projekte
    , gruppen = cfg.gruppen
    }


compareCi : String -> String -> Bool
compareCi a b =
    String.toLower a == String.toLower b


view : Model -> Html msg
view model =
    let
        abteilungen : String -> List String
        abteilungen mitarbeiter =
            model.gruppen
                |> List.filter (\g -> compareCi mitarbeiter g.gruppenLeiter || (g.mitglieder |> List.any (compareCi mitarbeiter)))
                |> List.map .abteilung

        abteilung : AbteilungCheck
        abteilung mitarbeiter1 mitarbeiter2 =
            List.any (\a -> abteilungen mitarbeiter2 |> List.any (compareCi a)) (abteilungen mitarbeiter1)
    in
    div []
        [ h1 [ style "background-color" "#dde", style "padding" "10px", style "margin" "0", style "height" "50px" ] [ text "Gruppenleiter Bewertungsmatrix" ]
        , a [ class "floating-button", href <| "/configurator#" ++ model.configEncoded ] [ text "✎" ]
        , div [ style "padding" "10px" ] (model.gruppen |> List.map (viewGruppe model.projekte abteilung))
        ]


viewGruppe : List Projekt -> AbteilungCheck -> Gruppe -> Html msg
viewGruppe projekte abteilung gruppe =
    details []
        [ summary [ style "margin-bottom" "10px", style "margin-top" "10px" ]
            [ span [ style "font-size" "16pt" ] [ text <| String.join " " [ gruppe.abteilung, "Gruppe", gruppe.gruppenLeiter ] ] ]
        , table []
            (tr []
                [ th [] []
                , th [] [ text "Feedback durch" ]
                , th [] []
                , th [] [ text "Feedback für" ]
                , th [] [ text "Projekte" ]
                , th [] [ text "Dateiname" ]
                , th [] [ text "E-Mail Text" ]
                ]
                :: (bewertungen projekte abteilung gruppe
                        |> List.map viewBewertung
                   )
            )
        ]


viewBewertung : Bewertung -> Html msg
viewBewertung bewertung =
    tr []
        [ td [ style "white-space" "nowrap" ] [ text bewertung.rolleBewerter ]
        , td [] [ text bewertung.nameBewerter ]
        , td [ style "white-space" "nowrap" ] [ text bewertung.rolleBewerteter ]
        , td [] [ text bewertung.nameBewerteter ]
        , td [] [ text <| String.join ", " bewertung.projekte ]
        , td [] [ text <| dateiname bewertung ]
        , td [] [ text <| textBewertung bewertung ]
        ]


textBewertung : Bewertung -> String
textBewertung bewertung =
    String.join " " <|
        [ bewertung.rolleBewerter
        , bewertung.nameBewerter
        , "bewertet"
        , bewertung.rolleBewerteter
        , bewertung.nameBewerteter
        ]
            ++ (if List.length bewertung.projekte > 0 then
                    [ "in"
                    , String.join ", " bewertung.projekte
                    ]

                else
                    []
               )


dateiname : Bewertung -> String
dateiname bewertung =
    String.join " "
        [ "20XX_1"
        , "Entwicklungsgespräch"
        , bewertung.rolleBewerter
        , bewertung.nameBewerter
        , "bewertet"
        , bewertung.rolleBewerteter
        , bewertung.nameBewerteter
        ]
